export const getMobileOperatingSystem = (): string => {
    // Get the user agent string
    const userAgent: string = navigator.userAgent || navigator.vendor || (window as any).opera;

    // Check for Windows Phone first because its user agent string may contain "Android"
    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
    }

    // Check for Android devices
    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    // Check for iOS devices (iPad, iPhone, iPod)
    // The condition !window.MSStream ensures that it's not a Windows device
    if ((/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) || userAgent.indexOf('Mac OS X') > 0) {
        return 'iOS';
    }

    // If no matches, return 'unknown'
    return 'unknown';
}